jQuery("document").ready(function($){

	var nav = $('.site-header');

	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {
			nav.addClass("f-nav");
		} else {
			nav.removeClass("f-nav");
		}
	});

});



jQuery("document").ready(function($){
	var addbtn = $('.sc-raq-add-to-quote-btn');
    var prodbtn = $('#AddToCart-product-template');
	$('.sc-raq-add-to-quote-btn').click(function () {
		if ($('.sc-raq-add-to-quote-btn').hasClass('raq-added-to-list')) {
			$('#AddToCart-product-template').toogleClass('botpicado');
		} else {
			$('#AddToCart-product-template').toogleClass('botpicado');
		}
	});
    

});

jQuery("document").ready(function($){
	var addbtn2 = $('button.sc-raq-add-to-quote-btn');
    var prodbtn2 = $('#AddToCart-product-template');

		if (addbtn2.hasClass('raq-added-to-list')) {
			prodbtn2.toggleClass('botpicado');
		} 
	
    

});